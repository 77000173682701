export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SEARCH_BOX_PLACEHOLDER: 'Search by keyword, brand or SKU',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'search-button',

  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/plus" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/minus" />`,
  FILTERS_ICON: '<rt-virtual template="icons/filter-filled" />',

  PRODUCT_REPEATER_CLASS: `productCards productCards--{this.view}{this.view === 'grid' ? ' category' : ''}`,

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
