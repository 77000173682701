const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = ['Submodel', 'EngineVin'];

const categorySelectionPageUrl = '/shop-by-category';
const brandSelectionPageUrl = '/brands/';

window.Convermax.handleFitmentTableTab = () =>
  window.document.querySelector('.cm_tab-content')?.classList.toggle('active');

function InitFunc() {
  if (window.document.querySelector('.category.category--grid .page-content')) {
    const unwantedPageWrappers = [['.container.category.category--grid:not(.productCards)', 'category']];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(className);
    });
  }
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  defaultView: 'grid',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl
        ? 'parts'
        : window.location.pathname === brandSelectionPageUrl
          ? 'brands'
          : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        selector: ['category', 'brand']
          .map((pageSelector) => `body.page-type-${pageSelector} .category--grid .page-content`)
          .join(),
        class: 'cm_SearchResult-category',
      },
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      pageSizes: [12, 24, 36, 48],
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 800,
      fields: ['Year', 'Make', 'Model', 'Engine', 'Submodel'],
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.page-type-default header',
        class: 'container cm_vehicle-widget__home_container',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
      visibleIf: () => window.location.pathname === '/',
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: ['EngineVin'],
    },
    {
      name: 'Garage',
      location: {
        insertAfter: 'nav.navUser .navUser-section .navUser-item--account',
        wrapper: 'li',
        class: 'cm_garage_container navUser-item navUser-item--garage',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: {
        insertBefore: 'body.page-type-product #add-to-cart-wrapper',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: { insertAfter: 'body.page-type-product .product-description' },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-category-list',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: '#quickSearch',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
      fields: ['Year', 'Make', 'Model', 'Engine', 'Submodel'],
    },
  ],
};
