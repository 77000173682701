import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/suajoeravj-1/Templates/SearchPage.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/suajoeravj-1/Templates/fitmentSearch/homeVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/suajoeravj-1/Templates/fitmentSearch/fitmentTable.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialogVehicleSpecs from 'Stores/suajoeravj-1/Templates/FacetDialogVehicleSpecs.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
FacetPanel,
VehicleWidget,
Garage,
VerifyFitment,
FitmentTable,
FacetTiles,
SearchBoxDialogButton,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'FacetDialogVehicleSpecs': TemplateFacetDialogVehicleSpecs,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};