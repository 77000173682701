//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-628:_4720,_5112,_9700,_1031,_8204,_720,_4988,_6468;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-628')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-628', "_4720,_5112,_9700,_1031,_8204,_720,_4988,_6468");
        }
      }catch (ex) {
        console.error(ex);
      }